import React from 'react'
import { Box, Button, Select, Text, Flex, Heading } from '../UI'
import Card from '../Card'
import styled from 'styled-components'
import PreviewCompatibleImage from '../PreviewCompatibleImg'
import ScrollableLink from '../ScrollableLink'

const formatPrice = (amount, currency) => {
  let price = (amount / 100).toFixed(2)
  let numberFormat = new Intl.NumberFormat(['en-US'], {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
  })
  return numberFormat.format(price)
}
const Prod = styled(Flex)`
  .img-string-src {
    display: block;
    object-fit: cover;
    border-radius: inherit;
    min-height: 200px;
    width: 100%;
    height: 100%;
  }
`
const ProductCard = ({ product }) => {
  const { slug, name, description, stripeProduct, stripePrices } = product
  if (!stripeProduct) return null
  return (
    <Prod flexDirection={{ _: 'column', sm: 'row' }}>
      <Box mr={2} mb={2}>
        <ScrollableLink to={`/product/${slug}`}>
          <PreviewCompatibleImage
            maxWidth={200}
            borderRadius={1}
            imageInfo={{ image: stripeProduct.images[0] }}
          />
        </ScrollableLink>
      </Box>
      <Flex alignItems="flex-start">
        <ScrollableLink to={`/product/${slug}`}>
          <Heading color="primary.2" fontSize={3}>
            {stripeProduct.name}
          </Heading>
        </ScrollableLink>
        <Text fontSize={1}>{stripeProduct.description}</Text>
        <Flex
          mb={2}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Text
            as="label"
            fontWeight={900}
            color="primary.1"
            mb={0}
            fontSize={2}
            mr={1}
          >
            {stripePrices
              ? formatPrice(
                  stripePrices[0].unit_amount,
                  stripePrices[0].currency
                )
              : 'Sold Out'}
          </Text>
        </Flex>
        <Button
          variant="secondary"
          fontSize={1}
          px={2}
          py={1}
          as={ScrollableLink}
          display="inline-flex"
          to={`/product/${slug}`}
        >
          View
        </Button>
      </Flex>
    </Prod>
  )
}

export default ProductCard
