import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import ProductCard from '../ProductCard'
import { Grid } from '../UI'

const Products = () => {
  const { allContentfulProduct } = useStaticQuery(graphql`
    query products {
      allContentfulProduct {
        edges {
          node {
            id
            stripeProductId
            slug
            title
            stripeProduct {
              id
              name
              description
              images
            }
            stripePrices {
              id
              active
              currency
              unit_amount
            }
          }
        }
      }
    }
  `)
  // // Group prices by product
  // const products = {}
  // for (const { node: price } of prices.edges) {
  //   const product = price.product
  //   if (!products[product.id]) {
  //     products[product.id] = product
  //     products[product.id].prices = []
  //   }
  //   products[product.id].prices.push(price)
  // }
  return (
    <Grid gap={2} cols={{ _: 1, lg: 1 }}>
      {allContentfulProduct.edges.map(({ node: product }) => (
        <ProductCard key={product.id} product={product} />
      ))}
    </Grid>
  )
  return (
    <div>
      {prices.edges.map(({ node: price }) => (
        <p key={price.id}>{price.product.name}</p>
      ))}
    </div>
  )
}

export default Products
