import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash.get'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import PageLayout from '../../components/PageLayout'
import Products from '../../components/Products'

const ShopTemplate = ({ data: { contentfulPage: page } }) => {
  const SeoImg =
    get(page, 'image.childImageSharp.sizes.src') || get(page, 'image.fluid.src')

  return (
    <div>
      <SEO
        title={page.title}
        description={page.description}
        image={SeoImg}
        pathname={page.slug}
        article
      />
      <Layout>
        <PageLayout
          horizontal={page.horizontal}
          image={SeoImg}
          page={page}
          afterContent={Products}
        />
      </Layout>
    </div>
  )
}

export default ShopTemplate

export const shopPageQuery = graphql`
  query shopPageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      title
      contentful_id
      slug
      heading
      subHeading
      image {
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid
        }
      }
      content {
        content
        json
      }
    }
  }
`
